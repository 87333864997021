var noHistory =false;
var isDislikePopupOpen = false;
var convoId = "";
enrollmentConfig = {};
var authToken = "";
const salesforceChatbotPath = '/chatbot';
let accessToken = null;
let tokenExpiry = null;
var inputTexboxStatus = false;
var citationsForTitle = {};


function getAccessTokenFromOktaApi() {
    return new Promise((resolve, reject) => {
        $.ajax({
            type: "GET",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            url: window.env.commonConfig.getAuthToken,
            success: function(response) {
                accessToken = response.access_token;
                tokenExpiry = Date.now() + response.expires_in * 1000; 
                resolve(accessToken);
            },
            error: function(xhr, status, error) {
                reject(error);
            }
        });
    });
}

function getValidAccessToken() {
    return new Promise((resolve, reject) => {
        if (accessToken && tokenExpiry > Date.now()) {
            resolve(accessToken);
        } else {
            getAccessTokenFromOktaApi().then(resolve).catch(reject);
        }
    });
}


function setApiUrlsForEnrollment(apiConfigData){
	enrollmentConfig = apiConfigData;	
}

 async function getChatbotIdForEnrollmentBot() {
	let headers = {
		'Content-Type': 'application/x-www-form-urlencoded',	
	}
	// Ensure the headers are updated before making the AJAX request
	headers = await addSubscriptionKeyForEnr(headers);
	$.ajax({
		type: "POST",
		headers: headers,
		url: window.env.commonConfig.getChatbotId,
		data: {
			chatbot_name: 'enrollment'
		},
		success: function(response) {
			window.env.enrollmentConfig.chatBotIdEnr = response.chatbot_id;
			sessionStorage.setItem('chatbotIdEnr', response.chatbot_id);
			SasTokenForEnrollmentChatbot();
		}
	});
}

//  async function checkSASCookiesForEnrollmentChatbot() {
// 	getValidAccessToken().then(token => { 
// 		const headers = {
// 			'Content-Type': 'application/x-www-form-urlencoded',
// 			'Authorization': 'Bearer ' + token,
// 		}
// 		addSubscriptionKey(headers);
// 	$.ajax({
// 		type: "POST",
// 		headers: headers,
// 		url: window.env.commonConfig.sasToken,
// 		success: function(response) {
// 			localStorage.setItem('sas_token', response.sas_token);
// 		}
// 	});
// }).catch(error => {
// 	console.error('Error getting access token: ', error);
// });
// } 

async function SasTokenForEnrollmentChatbot(){
	var headers = {
		'Content-Type': ''
	},
	headers = await addSubscriptionKeyForEnr(headers);
	$.ajax({
		type: "POST",
		headers: headers,
		url: window.env.commonConfig.sasToken,
		success: function(response) {
			localStorage.setItem('sas_token', response.sas_token);
		}
	});
}

async function refreshChatEnrollmentChatbot(e, id){
	const currentUrl = window.location.pathname;
	if (currentUrl !== chatbotPath) {
	$('.chat-history').addClass('d-none');
	$('.init-content-wrap').addClass('d-none');
	let headers = {
		'Content-Type': 'application/x-www-form-urlencoded'
	}
	headers = await addSubscriptionKeyForEnr(headers);
	$.ajax({
		type: "POST",
		headers: headers,
		url: window.env.commonConfig.refreshChat,
		data: {
			chatbot_id: window.env.enrollmentConfig.chatBotIdEnr,
			session_id: localStorage.getItem('session_uuid'),
		},
		success: function (response) {
			var inputTag = document.getElementById("input_msg_chat_popup");
			inputTag.disabled = false;
			inputTag.placeholder = "Ask me anything..."
			var bot_msg = response.text_response;
			
			setTimeout(function () {
				$('.hk-chatbot-popup .chatbot-popup-body ul.list-unstyled').empty();

				// Create and append a new list item
				var welcome_message = $(`<li class="media received">
											<div class="avatar avatar-xs avatar-soft-primary avatar-icon avatar-rounded">
												<div class="avatar avatar-xs avatar-rounded">
													<img src="${window.AssetsPath.brandingAssetsStudent.Charlotte}" alt="user" class="avatar-img" style="height: auto; width: auto;">
												</div>
											</div>
											<div class="media-body">
												<div class="msg-box">
													<div>
														<p>Hello! How can I assist you today?</p>
													</div>
												</div>
												
											</div>
										</li>`);
				$('.hk-chatbot-popup ul.list-unstyled').append(welcome_message);
				var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
				chatBox.scrollTop = chatBox.scrollHeight;
			}, 0);
		}
	});
	$('.dashboard').removeClass('d-none');
	} else {
		var getChatbotElement = document.getElementById('chatbot-body');
		
		if (getChatbotElement.classList.contains('welcome-acknowledge-body')) {
			console.log('welcome-acknowledge-body');
		} else {
			console.log('The class "chatbot-popup-body" is applied.');
			//const authToken = await getAccessTokenFromOktaApi();
	$('.chat-history').addClass('d-none');
	$('.init-content-wrap').addClass('d-none');
	let headers = {
		'Content-Type': 'application/x-www-form-urlencoded'
	}
	headers = await addSubscriptionKeyForEnr(headers);
	$.ajax({
		type: "POST",
		headers: headers,
		url: window.env.commonConfig.refreshChat,
		data: {
			chatbot_id: window.env.enrollmentConfig.chatBotIdEnr,
			session_id: localStorage.getItem('session_uuid'),
		},
		success: function (response) {
			var inputTag = document.getElementById("input_msg_chat_popup");
			inputTag.disabled = false;
			inputTag.placeholder = "Ask me anything..."
			var bot_msg = response.text_response;
			
			setTimeout(function () {
				$('.hk-chatbot-popup .chatbot-popup-body ul.list-unstyled').empty();

				// Create and append a new list item
				var welcome_message = $(`<li class="media received">
											<div class="avatar avatar-xs avatar-soft-primary avatar-icon avatar-rounded">
												<div class="avatar avatar-xs avatar-rounded">
												<div class="header-img" style="margin-left:-10px;">
													<img src="${window.AssetsPath.brandingAssetsStudent.Charlotte}" alt="user" class="avatar-img" style="height: auto; width: auto;">
</div>

												</div>
											</div>
											<div class="media-body">
												<div class="msg-box">
													<div>
														<p>Hello! How can I assist you today?</p>
													</div>
												</div>
												
											</div>
										</li>`);
				$('.hk-chatbot-popup ul.list-unstyled').append(welcome_message);
				var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
				chatBox.scrollTop = chatBox.scrollHeight;
			}, 0);
		}
	});
	$('.dashboard').removeClass('d-none');
	$('.list-unstyled,footer > .input-group').removeClass('d-none');
		}
		
	}	
}

async function inputMsgChatPopupEnrollmentChatbot(e, id){
	const currentPath = window.location.pathname;
	if (currentPath !== salesforceChatbotPath) {
	if ((e.which == 13)&&(!$(id).val().length == 0)) {
		const messageInputField = document.getElementById("input_msg_chat_popup");
		messageInputField.disabled = true;
		inputTextStatus(false);
		message = messageInputField.value.trim();
		$('<li class="media sent"><div class="media-body"><div class="msg-box"><div><p>' + $(id).val() + '</p> </div></div></div></li>').appendTo(".hk-chatbot-popup  ul.list-unstyled");
		$(id).val('');
		
		$(`<li class="media received typing-wrap">
			<div class="avatar avatar-xs avatar-soft-purple avatar-rounded"><img src="${window.AssetsPath.brandingAssetsStudent.Charlotte}" alt="user" class="avatar-img" style="height: auto; width: auto;"></div>
			<div class="media-body">
				<div class="msg-box">
					<div>
						<p>Looking for <strong>${message}</strong></p>
					</div>
				</div>
				<div class="msg-box">
					<div>
						<div class="typing">
							<div class="dot"></div>
							<div class="dot"></div>
							<div class="dot"></div>
						</div>
					</div>
				</div>
			</div>
		</li>`).appendTo('.hk-chatbot-popup .chatbot-popup-body ul.list-unstyled');
		
		var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
		chatBox.scrollTop = chatBox.scrollHeight;
		
		// Get the current date and time
		var now = new Date();
		var formatted_date = now.toISOString().slice(0, 19).replace(/-/g, "_").replace("T", "_").replace(/:/g, "_");
		convoId = localStorage.getItem('session_uuid') + "_" + formatted_date;
		const convo_id = convoId;
		let headers = {
			"Content-Type": "application/json",
		};
		headers = await addSubscriptionKeyForEnr(headers);
			const response = await fetch(window.env.commonConfig.searchApiRequest, {
				method: "POST",
				headers: headers,
				body: JSON.stringify({
					chatbot_id: window.env.enrollmentConfig.chatBotIdEnr,
					session_id: localStorage.getItem('session_uuid'),
					message: message,
					userEmail: sessionStorage.getItem('userEmail'),
					origin: "Enrollment",
					convo_id: convo_id,
					opportunity_id: ""
				}),
			});
		inputTextStatus(false);

		const decoder = new TextDecoder();
		const reader = response.body.getReader();

		let text_response = ""
		var rephrased_query = message;

		while(true) {
			const {done, value} = await reader.read();
			if (done) {
				let inputText = text_response;
				
				if(inputText.includes("$STATUS_CODE_BEGIN$200$STATUS_CODE_END$")){
					inputText = inputText.replace("$STATUS_CODE_BEGIN$200$STATUS_CODE_END$","")
				}
				if (inputText.includes('<!-- Cached Response -->') || inputText.includes('Cached Response')) {
					inputText = removeCitationLinksFromResponse(inputText);
				}
				// Regular expression pattern to find text enclosed in square brackets
				const citationPattern = /\[([^\]]+)\]/g;

				// Create an object to store unique citations with hyperlinks
				const citations = {};

				// Track the citation count
				let citationCount = 1;
				// Replace citations with hyperlinks and store them in the citations object
				const processedText = inputText.replace(citationPattern, (match, text) => {
					// Check if the text already has a citation number
					if (!citations[text]) {
						if ((text.startsWith("http")) || (text.startsWith("www") || (text.startsWith('academics.')) || (text.startsWith('academicanswers.')) || (text.startsWith('academicguides.')))){
							citations[text] = {
							text: text,
							hyperlink: `${text}`,
							citationNumber: citationCount++
							};
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;

						} else if (text.endsWith(".pdf")) {
							citations[text] = {
								text: text,
								hyperlink: `${window.env.commonConfig.citationPDF}-${window.env.enrollmentConfig.chatBotIdEnr}/${text}?${localStorage.getItem('sas_token')}`,
								citationNumber: citationCount++
							};
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;

						} else if ((text.startsWith("General")) || (text.startsWith("Database"))) {
							citations[text] = {
								text: text,
								hyperlink: `${text}`,
								citationNumber: citationCount++
							};
							return `<a target="_blank" class="badge badge-sm badge-light" style="margin-left:2px;" disabled>${citations[text].citationNumber}</a>`;
						} else {
							return ``
						}
					}
					else {
						if (text.startsWith("http")){
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;
						} else if (text.endsWith(".pdf")) {
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;
						} else if ((text.startsWith("General")) || (text.startsWith("Database"))) {
							return `<a target="_blank" class="badge badge-sm badge-light" style="margin-left:2px;" disabled>${citations[text].citationNumber}</a>`;
						} else {
							return ``
						}
					}
				});

				// Create a JSON object for the citations
				const citationsJSON = citations;
				citationsForTitle = citations;
				let citationText = "";
				const pdfCitations = [];
				var websiteCitations = [];
				var unverified = [];
				const citationPayloadLinks = [];
				var originalCitation = [];

				for (const key in citationsJSON) {
					if (citationsJSON.hasOwnProperty(key)) {
						const citation = citationsJSON[key];
						originalCitation.push(citation);
						const citationLink = citation.hyperlink;
						citationPayloadLinks.push(citationLink);
						const citationText = citation.text.replace("https://","").slice(0, 30);
						if (citationLink.endsWith(".pdf")) {
							pdfCitations.push(`<a href="${citationLink}" class="badge badge-primary mt-2" style="margin-left:2px;" target="_blank">${citation.citationNumber}. ${citationText}...</a>`);
						} else if (citationLink.startsWith("General")) {
							unverified.push(`<a class="badge badge-light mt-2" style="margin-left:2px;" target="_blank" disabled>${citation.citationNumber}. ${citationText}</a>`);
						} else if (citationLink.startsWith("Database")) {
							pdfCitations.push(`<a class="badge badge-light mt-2" style="margin-left:2px;" target="_blank" disabled>${citation.citationNumber}. ${citationText}</a>`);
						} else if (citationLink.startsWith("http")){
							websiteCitations.push(`<a href="${citationLink}" class="badge badge-primary mt-2" style="margin-left:2px;" target="_blank">${citation.citationNumber}. ${citationText}...</a>`);
						}
					}
				}
				// var pdfCitationText = pdfCitations.length > 0 ? `<div id="pdf_citation_msg_box_${convo_id}" class="msg-box" style="display:none"><span class="p-1 border border-light rounded-5"><p style="font-size:10px; text-align: left;">PDF citation</p>${pdfCitations.join("")}</span></div>` : "";
				// console.log('pdfCitationText: ', pdfCitationText);
				// var websiteCitationText = websiteCitations.length > 0 ? `<div id="enrollment_citation_msg_box_${convo_id}" class="msg-box" style="display:none"><span class=""><p style="font-size:10px; text-align: left;">Walden Owned Public Data Source</p>${websiteCitations.join("")}</span></div>` : "";
				// var unverifiedText = unverified.length > 0 ? `<div id="enrollment_unverified_citation_msg_box_${convo_id}" class="msg-box" style="display:none"><span class=""><p style="font-size:10px; text-align: left;">Walden UnVerified Public Data Source</p>${unverified.join("")}</span></div>` : "";
				setTimeout(function () {

					
					var typingBubble = document.querySelector('.media.received.typing-wrap');
					if (typingBubble) {
						typingBubble.parentNode.removeChild(typingBubble);
					}

					var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
					chatBox.scrollTop = chatBox.scrollHeight;

					const textElement = document.getElementById('streambubble');
					var streamingBubble = document.querySelector('.media.received.streambubble');
					if (streamingBubble) {
						streamingBubble.parentNode.removeChild(streamingBubble);
					}
					var checkFeedbackOptions = ['Response is too slow', 'Response is partially or completely incorrect (Please provide more details in the comments below)', 'Data source needs to be added or updated (Please provide more details in the comments below)'];
					$(`	
					<li class="media received">
						<div class="avatar avatar-xs avatar-soft-purple avatar-rounded"><img src="${window.AssetsPath.brandingAssetsStudent.Charlotte}" alt="user" class="avatar-img" style="height: auto; width: auto;"></div>
							<div class="media-body"><div class="msg-box">
								<div>
									<p id="${convo_id}_query">Generated results for <strong>${rephrased_query}</strong></p>
								</div>
								
							</div>
							<div class="msg-box">
								<div id="${convo_id}_response">
									<p id="${convo_id}_answer">${processedText}</p>
								</div>

							</div>
							<div style="border: 1px solid #D0DFF6;" class="mt-2">
								<div id="action-buttons">
								<div class="msg-action" style="background:transparent;">
								<p id="${convo_id}_action_update" value='' hidden></p>
								<button id="like_convo" data-convo_id='${convo_id}' data-convo_action='like' class="btn btn-icon btn-rounded btn-flush-primary btn-lg"  data-bs-toggle="dropdown"><span class="icon"><i class="fa fa-thumbs-up"></i></span></button>
								<div class="dropdown-menu dropdown-menu-end">
									<a class="dropdown-item">
										<label for="convo_feedback">Would you like to share a feedback?</label>
										<textarea class="form-control" data-convo_id='${convo_id}' id="${convo_id}_convo_feedback_like" type="text"></textarea>
										<button id="send_like_feedback" data-convo_id='${convo_id}' class="btn  btn-custom  btn-success icon-wthot-bg btn-rounded mt-1" style="float: inline-end;"><span><span>Send</span><span class="icon"><i class="fa fa-angle-right"></i> </span></span></button>
									</a>
								</div>
								<button id="dislike_convo" data-convo_id='${convo_id}' data-convo_action='dislike' class="btn btn-icon btn-rounded btn-flush-primary"><span class="icon"><i class="fa fa-thumbs-down"></i></span></button>
								<div id="dislikeCategory_${convo_id}" class="dropdown-menu dropdown-menu-end dislikeCategory">
								<a class="dropdown-item">
								<label for="convo_feedback">Would you like to share a feedback?</label>
								<div class="form-check">
								<input class="form-check-input" data-convo_id='${convo_id}' id="checkbox1_${convo_id}" type="checkbox" value="${checkFeedbackOptions[0]}">
								<label class="form-check-label" id="label1" for="checkbox1">
								${checkFeedbackOptions[0]}
								</label>
								  </div>
								  <div class="form-check">
								<input class="form-check-input" data-convo_id='${convo_id}' id="checkbox2_${convo_id}" type="checkbox" value="${checkFeedbackOptions[1]}">
								<label class="form-check-label" id="label2" for="checkbox2">
								Response is partially or completely incorrect <br>
               		 			<span>(Please provide more details in the comments below)</span>
								</label>
								  </div>
								  <div class="form-check">
								<input class="form-check-input" data-convo_id='${convo_id}' id="checkbox3_${convo_id}" type="checkbox" value="${checkFeedbackOptions[2]}">
								<label class="form-check-label" id="label3" for="checkbox3">
								Data source needs to be added or updated <br>
                				<span>(Please provide more details in the comments below)</span>
								</label>
								  </div>
								  
								  
								  <textarea class="form-control" data-convo_id='${convo_id}' id="${convo_id}_convo_feedback_dislike" type="text" placeholder="Provide additional feedback"></textarea>
								<button id="send_dislike_feedback" data-convo_id='${convo_id}' class="btn  btn-custom  btn-success icon-wthot-bg btn-rounded mt-1" style="float: inline-end;" disabled><span><span>Send</span><span class="icon"><i class="fa fa-angle-right"></i> </span></span></button>
							    </a>
									
								</div>
								
								<a id="enrollment_citation_btn_${convo_id}" data-convo_id='${convo_id}' style="margin: 2px 10px 2px 2px; margin-button: 15px; border: none; cursor: pointer"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16" style="margin-right: 5px;">
								<path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
								<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
							  </svg>Citation</a>
							  <a id="copy_response_btn_env" data-convo_id='${convo_id}' style="margin: 2px 10px 2px 2px; margin-button: 15px; border: none; cursor: pointer"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16" style="margin-right: 5px;">
							  <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
							  </svg>Copy Response</a>
							<a id="disclaimer" data-convo_id='${convo_id}' data-convo_action='disclaimer'  data-bs-toggle="dropdown"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
							<path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"/>
							<path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
						  </svg>Disclaimer</a>
						  <div class="dropdown-menu dropdown-menu-end">
						  <a class="dropdown-item">
						  <p class="form-control" data-convo_id='${convo_id}' id="${convo_id}_disclaimer" type="text" style="border-color: red; white-space: normal;">
						  <strong>Disclaimer:</strong> While interacting in this chat window, you are interacting
						  with a chatbot that uses generative AI, or materials created by generative
						  AI. If you are ever transferred to a real person, our human representative
						  will introduce themselves during the chat. If you have any questions
						  regarding this notice, please email Algovernance@adtalem.com
					  </p>
						  </a>
								</div>
							</div>
								</div>

							</div>
							<div id="${convo_id}_cached_response_text" data-convo_id='${convo_id}' style="display: none; size: 16px" class="mt-2">
                            <b>${window.env.enrollmentConfig.cachedResponseConstant}</b>
                            </div>
							<div id="${convo_id}_apology_text" data-convo_id='${convo_id}' style="display: none; font-family: Halant; size: 16px" class="mt-2">
							<p>Sorry for the inadequate response. As a bot, I will use this feedback to get better!</p>
							</div>
							<div id="citation_msg_div_${convo_id}" class="mt-2" style="display:none">
							
							</div>
						</div>
					</li>`).appendTo(".hk-chatbot-popup .chatbot-popup-body ul.list-unstyled");
					var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
					chatBox.scrollTop = chatBox.scrollHeight;
					if (inputText.includes('<!-- Cached Response -->') || inputText.includes('Cached Response')) {
						findCatchResponse(convo_id);
					}
					
				}, 0);
				// getCitationLinksApiCallForEnrollment(citationPayloadLinks, convo_id);
				break
			} 
			else {
				// text_response += decoder.decode(value);
				let inputText = text_response;
				var rq_regex = /\$SRQ\$(.*?)\$ERQ\$/;
				var rq_match = decoder.decode(value).match(rq_regex);
				// console.log(rq_match);
				if (rq_match && rq_match.length > 1) {
					rephrased_query = rq_match[1];
					inputText = text_response;
				} else {
					text_response += decoder.decode(value);
					inputText = text_response;
				}

				var regex = /\$FINAL_CONTEXT\$(.*)/;
				// Extracting the text after $FINAL_CONTEXT$ using the regular expression
				var match = decoder.decode(value).match(regex);

				// Check if there is a match and extract the text
				if (match && match.length > 1) {
					var extractedText = match[1].trim();
					text_response = extractedText;
					inputText = text_response;
				} else {
					console.log("Text after $FINAL_CONTEXT$ not found!");
				}
				if (inputText.includes('Cached Response') || inputText.includes('<!-- Cached Response -->')) {
					inputText = removeCitationLinksFromResponse(inputText);
				}
				//console.log(rephrased_query);
				// Regular expression pattern to find text enclosed in square brackets
				const citationPattern = /\[([^\]]+)\]/g;

				// Create an object to store unique citations with hyperlinks
				const citations = {};

				// Track the citation count
				let citationCount = 1;

				// Replace citations with hyperlinks and store them in the citations object
				const processedText = inputText.replace(citationPattern, (match, text) => {
					// Check if the text already has a citation number
					if (!citations[text]) {
						if ((text.startsWith("http")) || (text.startsWith("www") || (text.startsWith('academics.')) || (text.startsWith('academicanswers.')) || (text.startsWith('academicguides.')))){
							citations[text] = {
							text: text,
							hyperlink: `${text}`,
							citationNumber: citationCount++
							};
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;

						} else if (text.endsWith(".pdf")) {
							citations[text] = {
								text: text,
								hyperlink: `${window.env.commonConfig.citationPDF}-${window.env.enrollmentConfig.chatBotIdEnr}/${text}?${localStorage.getItem('sas_token')}`,
								citationNumber: citationCount++
							};
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;

						} else if ((text.startsWith("General")) || (text.startsWith("Database"))) {
							citations[text] = {
								text: text,
								hyperlink: `${text}`,
								citationNumber: citationCount++
							};
							return `<a target="_blank" class="badge badge-sm badge-light" style="margin-left:2px;" disabled>${citations[text].citationNumber}</a>`;
						} else {
							return ``
						}
					}
					else {
						if (text.startsWith("http")){
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;
						} else if (text.endsWith(".pdf")) {
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;
						} else if ((text.startsWith("General")) || (text.startsWith("Database"))) {
							return `<a target="_blank" class="badge badge-sm badge-light" style="margin-left:2px;" disabled>${citations[text].citationNumber}</a>`;
						} else {
							return ``
						}
					}
				});

				// Create a JSON object for the citations
				const citationsJSON = citations;
				citationsForTitle = citations;
				let citationText = "";
				const pdfCitations = [];
				const websiteCitations = [];
				const unverified = [];
				var citationPayloadLinks = [];

				for (const key in citationsJSON) {
					if (citationsJSON.hasOwnProperty(key)) {
						const citation = citationsJSON[key];
						const citationLink = citation.hyperlink;
						citationPayloadLinks.push(citationLink);
						const citationText = citation.text.replace("https://","").slice(0, 30);
						if (citationLink.endsWith(".pdf")) {
							pdfCitations.push(`<a href="${citationLink}" class="badge badge-primary mt-2" style="margin-left:2px;" target="_blank">${citation.citationNumber}. ${citationText}...</a>`);
						} else if (citationLink.startsWith("General")) {
							unverified.push(`<a class="badge badge-light mt-2" style="margin-left:2px;" target="_blank" disabled>${citation.citationNumber}. ${citationText}</a>`);
						} else if (citationLink.startsWith("Database")) {
							pdfCitations.push(`<a class="badge badge-light mt-2" style="margin-left:2px;" target="_blank" disabled>${citation.citationNumber}. ${citationText}</a>`);
						} else if (citationLink.startsWith("http")){
							websiteCitations.push(`<a href="${citationLink}" class="badge badge-primary mt-2" style="margin-left:2px;" target="_blank">${citation.citationNumber}. ${citationText}...</a>`);
						}
					}
				}
				// const pdfCitationText = pdfCitations.length > 0 ? `<div id="pdf_citation_msg_box_${convo_id}" class="msg-box" style="display:none"><span class="p-1 border border-light rounded-5"><p style="font-size:10px; text-align: left;">PDF citation</p>${pdfCitations.join("")}</span></div>` : "";
				// const websiteCitationText = websiteCitations.length > 0 ? `<div class="msg-box" style="display:none"><span class=""><p style="font-size:10px; text-align: left;">Walden Owned Public Data Source</p>${websiteCitations.join("")}</span></div>` : "";
				// const unverifiedText = unverified.length > 0 ? `<div class="msg-box"><span class=""><p style="font-size:10px; text-align: left;">Walden UnVerified Public Data Source</p>${unverified.join("")}</span></div>` : "";

				setTimeout(function () {

					
					var typingBubble = document.querySelector('.media.received.typing-wrap');
					if (typingBubble) {
						typingBubble.parentNode.removeChild(typingBubble);
					}

					var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
					chatBox.scrollTop = chatBox.scrollHeight;

					const textElement = document.getElementById('streambubble');
					var streamingBubble = document.querySelector('.media.received.streambubble');
					if (streamingBubble) {
						streamingBubble.parentNode.removeChild(streamingBubble);
					}
					var checkFeedbackOptions = ['Response is too slow', 'Response is partially or completely incorrect (Please provide more details in the comments below)', 'Data source needs to be added or updated (Please provide more details in the comments below)'];
					
					$(`	
					<li class="media received streambubble">
						<div class="avatar avatar-xs avatar-soft-purple avatar-rounded"><img src="${window.AssetsPath.brandingAssetsStudent.Charlotte}" alt="user" class="avatar-img" style="height: auto; width: auto;"></div>
							<div class="media-body"><div class="msg-box">
								<div>
									<p id="${convo_id}_query">Generating results for <strong>${rephrased_query}</strong></p>
								</div>
								
							</div>
							<div class="msg-box">
								<div id="${convo_id}_response">
									<p id="${convo_id}_answer">${processedText}</p>
									<div class="typing" style="justify-content: right;padding-top: 10px;">
										<div class="dot"></div>
										<div class="dot"></div>
										<div class="dot"></div>
									</div>
								</div>
								
							</div>
							<div style="border: 1px solid #D0DFF6;" class="mt-2">
								<div id="action-buttons">
								<div class="msg-action" style="background:transparent;">
								<p id="${convo_id}_action_update" value='' hidden></p>
								<button id="like_convo" data-convo_id='${convo_id}' data-convo_action='like' class="btn btn-icon btn-rounded btn-flush-primary btn-lg"  data-bs-toggle="dropdown"><span class="icon"><i class="fa fa-thumbs-up"></i></span></button>
								<div class="dropdown-menu dropdown-menu-end">
									<a class="dropdown-item">
										<label for="convo_feedback">Would you like to share a feedback?</label>
										<textarea class="form-control" data-convo_id='${convo_id}' id="${convo_id}_convo_feedback_like" type="text"></textarea>
										<button id="send_like_feedback" data-convo_id='${convo_id}' class="btn  btn-custom  btn-success icon-wthot-bg btn-rounded mt-1" style="float: inline-end;"><span><span>Send</span><span class="icon"><i class="fa fa-angle-right"></i> </span></span></button>
									</a>
								</div>
								<button id="dislike_convo" data-convo_id='${convo_id}' data-convo_action='dislike' class="btn btn-icon btn-rounded btn-flush-primary"><span class="icon"><i class="fa fa-thumbs-down"></i></span></button>
								<div id="dislikeCategory_${convo_id}" class="dropdown-menu dropdown-menu-end dislikeCategory">
									<a class="dropdown-item">
										<label for="convo_feedback">Would you like to share a feedback?</label>
										<div class="form-check">
										<input class="form-check-input" data-convo_id='${convo_id}' id="checkbox1_${convo_id}" type="checkbox" value="${checkFeedbackOptions[0]}">
										<label class="form-check-label" id="label1" for="checkbox1">
										${checkFeedbackOptions[0]}
										</label>
										  </div>
										  <div class="form-check">
										<input class="form-check-input" data-convo_id='${convo_id}' id="checkbox2_${convo_id}" type="checkbox" value="${checkFeedbackOptions[1]}">
										<label class="form-check-label" id="label2" for="checkbox2">
										Response is partially or completely incorrect <br>
               		 					<span>(Please provide more details in the comments below)</span>
										</label>
										  </div>
										  <div class="form-check">
										<input class="form-check-input" data-convo_id='${convo_id}' id="checkbox3_${convo_id}" type="checkbox" value="${checkFeedbackOptions[2]}">
										<label class="form-check-label" id="label3" for="checkbox3">
										Data source needs to be added or updated <br>
                						<span>(Please provide more details in the comments below)</span>
										</label>
										  </div>
										  
										  <textarea class="form-control" data-convo_id='${convo_id}' id="${convo_id}_convo_feedback_dislike" type="text" placeholder="Provide additional feedback"></textarea>
										<button id="send_dislike_feedback" data-convo_id='${convo_id}' class="btn  btn-custom  btn-success icon-wthot-bg btn-rounded mt-1" style="float: inline-end;" disabled><span><span>Send</span><span class="icon"><i class="fa fa-angle-right"></i> </span></span></button>
									</a>
									
								</div>
								<a id="enrollment_citation_btn_${convo_id}" data-convo_id='${convo_id}' style="margin: 2px 10px 2px 2px; margin-button: 15px; border: none; cursor: pointer"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16" style="margin-right: 5px;">
								<path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
								<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
							  </svg>Citation</a>
							  <a id="copy_response_btn_env" data-convo_id='${convo_id}' style="margin: 2px 10px 2px 2px; margin-button: 15px; border: none; cursor: pointer"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16" style="margin-right: 5px;">
							  <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
							  </svg>Copy Response</a>
							  <a id="disclaimer" data-convo_id='${convo_id}' data-convo_action='disclaimer'  data-bs-toggle="dropdown"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
							<path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"/>
							
						  </svg>Disclaimer</a>
						  
						  <div class="dropdown-menu dropdown-menu-end">
									<a class="dropdown-item">
									<p class="form-control" data-convo_id='${convo_id}' id="${convo_id}_disclaimer" type="text" style="border-color: red; white-space: normal;">
						  <strong>Disclaimer:</strong> While interacting in this chat window, you are interacting
						  with a chatbot that uses generative AI, or materials created by generative
						  AI. If you are ever transferred to a real person, our human representative
						  will introduce themselves during the chat. If you have any questions
						  regarding this notice, please email Algovernance@adtalem.com
					  </p>
									</a>
								</div>
							</div>
							</div>
								</div>
								
							</div>
							<div id="${convo_id}_cached_response_text" data-convo_id='${convo_id}' style="display: none; size: 16px" class="mt-2">
                            <b>${window.env.enrollmentConfig.cachedResponseConstant}</b>
                            </div>
							<div id="${convo_id}_apology_text" data-convo_id='${convo_id}' style="display: none; font-family: Halant; size: 16px" class="mt-2">
							<p>Sorry for the inadequate response. As a bot, I will use this feedback to get better!</p>
							</div>
							<div id="citation_msg_div_${convo_id}" class="mt-2" style="display:none">
								
							</div>
						</div>
					</li>`).appendTo(".hk-chatbot-popup .chatbot-popup-body ul.list-unstyled");

					var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
					chatBox.scrollTop = chatBox.scrollHeight;
					if (inputText.includes('Cached Response')) {
						findCatchResponse(convo_id);
					}
				}, 0);
			}
		}
		getCitationLinksApiCallForEnrollment(citationPayloadLinks, convo_id);
	}
} else {
	if ((e.which == 13)&&(!$(id).val().length == 0)) {
		const messageInputField = document.getElementById("input_msg_chat_popup");
		messageInputField.disabled = true;
		inputTextStatus(false);
		message = messageInputField.value.trim();
		$('<li class="media sent"><div class="media-body"><div class="msg-box"><div><p>' + $(id).val() + '</p> </div></div></div></li>').appendTo(".hk-chatbot-popup  ul.list-unstyled");
		$(id).val('');
		
		$(`<li class="media received typing-wrap">
			<div class="avatar avatar-xs avatar-soft-purple avatar-rounded">
			<div class="header-img" style="margin-left:-10px;">
						<img src="${window.AssetsPath.brandingAssetsStudent.Charlotte}" alt="user" class="avatar-img" style="height: auto; width: auto;">

						</div>

</div>
			<div class="media-body">
				<div class="msg-box">
					<div>
						<p>Looking for <strong>${message}</strong></p>
					</div>
				</div>
				<div class="msg-box">
					<div>
						<div class="typing">
							<div class="dot"></div>
							<div class="dot"></div>
							<div class="dot"></div>
						</div>
					</div>
				</div>
			</div>
		</li>`).appendTo('.hk-chatbot-popup .chatbot-popup-body ul.list-unstyled');
		
		var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
		chatBox.scrollTop = chatBox.scrollHeight;
		
		// Get the current date and time
		var now = new Date();
		var formatted_date = now.toISOString().slice(0, 19).replace(/-/g, "_").replace("T", "_").replace(/:/g, "_");
		convoId = localStorage.getItem('session_uuid') + "_" + formatted_date;
		var getopportunityId = sessionStorage.getItem('opportunityId');
		const convo_id = convoId;
		let headers = {
			"Content-Type": "application/json",
		};
		headers = await addSubscriptionKeyForEnr(headers);
			const response = await fetch(window.env.commonConfig.searchApiRequest, {
				method: "POST",
				headers: headers,
				body: JSON.stringify({
					chatbot_id: window.env.enrollmentConfig.chatBotIdEnr,
					session_id: localStorage.getItem('session_uuid'),
					message: message,
					userEmail: sessionStorage.getItem('userEmail'),
					origin: "Enrollment",
					convo_id: convo_id,
					opportunity_id: getopportunityId
				}),
			});
		inputTextStatus(false);

		const decoder = new TextDecoder();
		const reader = response.body.getReader();

		let text_response = ""
		var rephrased_query = message;

		while(true) {
			const {done, value} = await reader.read();
			if (done) {
				let inputText = text_response;
				if(inputText.includes("$STATUS_CODE_BEGIN$200$STATUS_CODE_END$")){
					inputText = inputText.replace("$STATUS_CODE_BEGIN$200$STATUS_CODE_END$","")
				}

				if (inputText.includes('<!-- Cached Response -->') || inputText.includes('Cached Response')) {
					inputText = removeCitationLinksFromResponse(inputText);
				}

				// Regular expression pattern to find text enclosed in square brackets
				const citationPattern = /\[([^\]]+)\]/g;

				// Create an object to store unique citations with hyperlinks
				const citations = {};

				// Track the citation count
				let citationCount = 1;

				// Replace citations with hyperlinks and store them in the citations object
				const processedText = inputText.replace(citationPattern, (match, text) => {
					// Check if the text already has a citation number
					if (!citations[text]) {
						if ((text.startsWith("http")) || (text.startsWith("www") || (text.startsWith('academics.')) || (text.startsWith('academicanswers.')) || (text.startsWith('academicguides.')))){
							citations[text] = {
							text: text,
							hyperlink: `${text}`,
							citationNumber: citationCount++
							};
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;

						} else if (text.endsWith(".pdf")) {
							citations[text] = {
								text: text,
								hyperlink: `${window.env.commonConfig.citationPDF}-${window.env.enrollmentConfig.chatBotIdEnr}/${text}?${localStorage.getItem('sas_token')}`,
								citationNumber: citationCount++
							};
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;

						} else if ((text.startsWith("General")) || (text.startsWith("Database"))) {
							citations[text] = {
								text: text,
								hyperlink: `${text}`,
								citationNumber: citationCount++
							};
							return `<a target="_blank" class="badge badge-sm badge-light" style="margin-left:2px;" disabled>${citations[text].citationNumber}</a>`;
						} else {
							return ``
						}
					}
					else {
						if (text.startsWith("http")){
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;
						} else if (text.endsWith(".pdf")) {
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;
						} else if ((text.startsWith("General")) || (text.startsWith("Database"))) {
							return `<a target="_blank" class="badge badge-sm badge-light" style="margin-left:2px;" disabled>${citations[text].citationNumber}</a>`;
						} else {
							return ``
						}
					}
				});

				// Create a JSON object for the citations
				const citationsJSON = citations;
				citationsForTitle = citations;
				let citationText = "";
				const pdfCitations = [];
				var websiteCitations = [];
				var unverified = [];
				var citationPayloadLinks = [];
				var originalCitation = [];

				for (const key in citationsJSON) {
					if (citationsJSON.hasOwnProperty(key)) {
						const citation = citationsJSON[key];
						originalCitation.push(citation);
						const citationLink = citation.hyperlink;
						citationPayloadLinks.push(citationLink);
						const citationText = citation.text.replace("https://","").slice(0, 30);
						if (citationLink.endsWith(".pdf")) {
							pdfCitations.push(`<a href="${citationLink}" class="badge badge-primary mt-2" style="margin-left:2px;" target="_blank">${citation.citationNumber}. ${citationText}...</a>`);
						} else if (citationLink.startsWith("General")) {
							unverified.push(`<a class="badge badge-light mt-2" style="margin-left:2px;" target="_blank" disabled>${citation.citationNumber}. ${citationText}</a>`);
						} else if (citationLink.startsWith("Database")) {
							pdfCitations.push(`<a class="badge badge-light mt-2" style="margin-left:2px;" target="_blank" disabled>${citation.citationNumber}. ${citationText}</a>`);
						} else if (citationLink.startsWith("http")){
							websiteCitations.push(`<a href="${citationLink}" class="badge badge-primary mt-2" style="margin-left:2px;" target="_blank">${citation.citationNumber}. ${citationText}...</a>`);
						}
					}
				}
				// var pdfCitationText = pdfCitations.length > 0 ? `<div id="pdf_citation_msg_box_${convo_id}" class="msg-box" style="display:none"><span class="p-1 border border-light rounded-5"><p style="font-size:10px; text-align: left;">PDF citation</p>${pdfCitations.join("")}</span></div>` : "";
				// console.log('pdfCitationText: ', pdfCitationText);
				// var websiteCitationText = websiteCitations.length > 0 ? `<div id="enrollment_citation_msg_box_${convo_id}" class="msg-box" style="display:none"><span class=""><p style="font-size:10px; text-align: left;">Walden Owned Public Data Source</p>${websiteCitations.join("")}</span></div>` : "";
				// var unverifiedText = unverified.length > 0 ? `<div id="enrollment_unverified_citation_msg_box_${convo_id}" class="msg-box" style="display:none"><span class=""><p style="font-size:10px; text-align: left;">Walden UnVerified Public Data Source</p>${unverified.join("")}</span></div>` : "";
				setTimeout(function () {

					
					var typingBubble = document.querySelector('.media.received.typing-wrap');
					if (typingBubble) {
						typingBubble.parentNode.removeChild(typingBubble);
					}

					var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
					chatBox.scrollTop = chatBox.scrollHeight;

					const textElement = document.getElementById('streambubble');
					var streamingBubble = document.querySelector('.media.received.streambubble');
					if (streamingBubble) {
						streamingBubble.parentNode.removeChild(streamingBubble);
					}
					var checkFeedbackOptions = ['Response is too slow', 'Response is partially or completely incorrect (Please provide more details in the comments below)', 'Data source needs to be added or updated (Please provide more details in the comments below)'];
					$(`	
					<li class="media received">
						<div class="avatar avatar-xs avatar-soft-purple avatar-rounded">
						<div class="header-img" style="margin-left:-8px;">
						<img src="${window.AssetsPath.brandingAssetsStudent.Charlotte}" alt="user" class="avatar-img" style="height: auto; width: auto;">

						</div>
</div>
							<div class="media-body"><div class="msg-box">
								<div>
									<p id="${convo_id}_query">Generated results for <strong>${rephrased_query}</strong></p>
								</div>
								
							</div>
							<div class="msg-box">
								<div id="${convo_id}_response">
									<p id="${convo_id}_answer">${processedText}</p>
								</div>

							</div>
							<div style="border: 1px solid #D0DFF6;" class="mt-2">
								<div id="action-buttons">
								<div class="msg-action" style="background:transparent;">
								<p id="${convo_id}_action_update" value='' hidden></p>
								<button id="like_convo" data-convo_id='${convo_id}' data-convo_action='like' class="btn btn-icon btn-rounded btn-flush-primary btn-lg"  data-bs-toggle="dropdown"><span class="icon"><i class="fa fa-thumbs-up"></i></span></button>
								<div id="likeCategory_${convo_id}" class="dropdown-menu dropdown-menu-end dislikeCategory">
									<a class="dropdown-item">
										<label for="convo_feedback">Would you like to share a feedback?</label>
										<textarea class="form-control" data-convo_id='${convo_id}' id="${convo_id}_convo_feedback_like" type="text"></textarea>
										<button id="send_like_feedback" data-convo_id='${convo_id}' class="btn  btn-custom  btn-success icon-wthot-bg btn-rounded mt-1" style="float: inline-end;"><span><span>Send</span><span class="icon"><i class="fa fa-angle-right"></i> </span></span></button>
									</a>
								</div>
								<button id="dislike_convo" data-convo_id='${convo_id}' data-convo_action='dislike' class="btn btn-icon btn-rounded btn-flush-primary"><span class="icon"><i class="fa fa-thumbs-down"></i></span></button>
								<div id="dislikeCategory_${convo_id}" class="dropdown-menu dropdown-menu-end dislikeCategory">
								<a class="dropdown-item">
								<label for="convo_feedback">Would you like to share a feedback?</label>
								<div class="form-check">
								<input class="form-check-input" data-convo_id='${convo_id}' id="checkbox1_${convo_id}" type="checkbox" value="${checkFeedbackOptions[0]}">
								<label class="form-check-label" id="label1" for="checkbox1">
								${checkFeedbackOptions[0]}
								</label>
								  </div>
								  <div class="form-check">
								<input class="form-check-input" data-convo_id='${convo_id}' id="checkbox2_${convo_id}" type="checkbox" value="${checkFeedbackOptions[1]}">
								<label class="form-check-label" id="label2" for="checkbox2">
								Response is partially or completely incorrect <br>
               		 			<span>(Please provide more details in the comments below)</span>
								</label>
								  </div>
								  <div class="form-check">
								<input class="form-check-input" data-convo_id='${convo_id}' id="checkbox3_${convo_id}" type="checkbox" value="${checkFeedbackOptions[2]}">
								<label class="form-check-label" id="label3" for="checkbox3">
								Data source needs to be added or updated <br>
                				<span>(Please provide more details in the comments below)</span>
								</label>
								  </div>
								  
								  
								  <textarea class="form-control" data-convo_id='${convo_id}' id="${convo_id}_convo_feedback_dislike" type="text" placeholder="Provide additional feedback"></textarea>
								<button id="send_dislike_feedback" data-convo_id='${convo_id}' class="btn  btn-custom  btn-success icon-wthot-bg btn-rounded mt-1" style="float: inline-end;" disabled><span><span>Send</span><span class="icon"><i class="fa fa-angle-right"></i> </span></span></button>
							    </a>
									
								</div>
								
								<a id="enrollment_citation_btn_${convo_id}" data-convo_id='${convo_id}' style="margin: 2px 10px 2px 2px; margin-button: 15px; border: none; cursor: pointer"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16" style="margin-right: 5px;">
								<path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
								<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
							  </svg>Citation</a>
							  <a id="copy_response_btn_env" data-convo_id='${convo_id}' style="margin: 2px 10px 2px 2px; margin-button: 15px; border: none; cursor: pointer"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16" style="margin-right: 5px;">
							  <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
							  </svg>Copy Response</a>
							<a id="disclaimer" data-convo_id='${convo_id}' data-convo_action='disclaimer'  data-bs-toggle="dropdown"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
							<path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"/>
							<path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
						  </svg>Disclaimer</a>
						  <div class="dropdown-menu dropdown-menu-end">
						  <a class="dropdown-item">
						  <p class="form-control" data-convo_id='${convo_id}' id="${convo_id}_disclaimer" type="text" style="border-color: red; white-space: normal;">
						  <strong>Disclaimer:</strong> While interacting in this chat window, you are interacting
						  with a chatbot that uses generative AI, or materials created by generative
						  AI. If you are ever transferred to a real person, our human representative
						  will introduce themselves during the chat. If you have any questions
						  regarding this notice, please email Algovernance@adtalem.com
					  </p>
						  </a>
								</div>
							</div>
								</div>

							</div>
							<div id="${convo_id}_cached_response_text" data-convo_id='${convo_id}' style="display: none; size: 16px" class="mt-2">
                            <b>${window.env.enrollmentConfig.cachedResponseConstant}</b>
                            </div>
							<div id="${convo_id}_apology_text" data-convo_id='${convo_id}' style="display: none; font-family: Halant; size: 16px" class="mt-2">
							<p>Sorry for the inadequate response. As a bot, I will use this feedback to get better!</p>
							</div>
							<div id="citation_msg_div_${convo_id}" class="mt-2" style="display:none">
								
								</div>
							</div>
						</div>
					</li>`).appendTo(".hk-chatbot-popup .chatbot-popup-body ul.list-unstyled");
					var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
					chatBox.scrollTop = chatBox.scrollHeight;
					if (inputText.includes('<!-- Cached Response -->') || inputText.includes('Cached Response')) {
						findCatchResponse(convo_id);
					}
				}, 0);
				// getCitationLinksApiCallForEnrollment(citationPayloadLinks, convo_id);

				break
			} 
			else {
				// text_response += decoder.decode(value);
				let inputText = text_response;
				var rq_regex = /\$SRQ\$(.*?)\$ERQ\$/;
				var rq_match = decoder.decode(value).match(rq_regex);
				// console.log(rq_match);
				if (rq_match && rq_match.length > 1) {
					rephrased_query = rq_match[1];
					inputText = text_response;
				} else {
					text_response += decoder.decode(value);
					inputText = text_response;
				}

				var regex = /\$FINAL_CONTEXT\$(.*)/;
				// Extracting the text after $FINAL_CONTEXT$ using the regular expression
				var match = decoder.decode(value).match(regex);

				// Check if there is a match and extract the text
				if (match && match.length > 1) {
					var extractedText = match[1].trim();
					text_response = extractedText;
					inputText = text_response;
				} else {
					console.log("Text after $FINAL_CONTEXT$ not found!");
				}

				if (inputText.includes('<!-- Cached Response -->') || inputText.includes('Cached Response')) {
					removeCitationLinksFromResponse(inputText);
				}
				
				//console.log(rephrased_query);
				// Regular expression pattern to find text enclosed in square brackets
				const citationPattern = /\[([^\]]+)\]/g;

				// Create an object to store unique citations with hyperlinks
				const citations = {};

				// Track the citation count
				let citationCount = 1;

				// Replace citations with hyperlinks and store them in the citations object
				const processedText = inputText.replace(citationPattern, (match, text) => {
					// Check if the text already has a citation number
					if (!citations[text]) {
						if ((text.startsWith("http")) || (text.startsWith("www") || (text.startsWith('academics.')) || (text.startsWith('academicanswers.')) || (text.startsWith('academicguides.')))){
							citations[text] = {
							text: text,
							hyperlink: `${text}`,
							citationNumber: citationCount++
							};
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;

						} else if (text.endsWith(".pdf")) {
							citations[text] = {
								text: text,
								hyperlink: `${window.env.commonConfig.citationPDF}-${window.env.enrollmentConfig.chatBotIdEnr}/${text}?${localStorage.getItem('sas_token')}`,
								citationNumber: citationCount++
							};
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;

						} else if ((text.startsWith("General")) || (text.startsWith("Database"))) {
							citations[text] = {
								text: text,
								hyperlink: `${text}`,
								citationNumber: citationCount++
							};
							return `<a target="_blank" class="badge badge-sm badge-light" style="margin-left:2px;" disabled>${citations[text].citationNumber}</a>`;
						} else {
							return ``
						}
					}
					else {
						if (text.startsWith("http")){
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;
						} else if (text.endsWith(".pdf")) {
							return `<a href="${citations[text].hyperlink}" target="_blank" class="badge badge-sm badge-primary" style="margin-left:2px;">${citations[text].citationNumber}</a>`;
						} else if ((text.startsWith("General")) || (text.startsWith("Database"))) {
							return `<a target="_blank" class="badge badge-sm badge-light" style="margin-left:2px;" disabled>${citations[text].citationNumber}</a>`;
						} else {
							return ``
						}
					}
				});

				// Create a JSON object for the citations
				const citationsJSON = citations;
				citationsForTitle = citations;
				let citationText = "";
				const pdfCitations = [];
				const websiteCitations = [];
				const unverified = [];

				for (const key in citationsJSON) {
					if (citationsJSON.hasOwnProperty(key)) {
						const citation = citationsJSON[key];
						const citationLink = citation.hyperlink;
						const citationText = citation.text.replace("https://","").slice(0, 30);
						if (citationLink.endsWith(".pdf")) {
							pdfCitations.push(`<a href="${citationLink}" class="badge badge-primary mt-2" style="margin-left:2px;" target="_blank">${citation.citationNumber}. ${citationText}...</a>`);
						} else if (citationLink.startsWith("General")) {
							unverified.push(`<a class="badge badge-light mt-2" style="margin-left:2px;" target="_blank" disabled>${citation.citationNumber}. ${citationText}</a>`);
						} else if (citationLink.startsWith("Database")) {
							pdfCitations.push(`<a class="badge badge-light mt-2" style="margin-left:2px;" target="_blank" disabled>${citation.citationNumber}. ${citationText}</a>`);
						} else if (citationLink.startsWith("http")){
							websiteCitations.push(`<a href="${citationLink}" class="badge badge-primary mt-2" style="margin-left:2px;" target="_blank">${citation.citationNumber}. ${citationText}...</a>`);
						}
					}
				}
				// const pdfCitationText = pdfCitations.length > 0 ? `<div id="pdf_citation_msg_box_${convo_id}" class="msg-box" style="display:none"><span class="p-1 border border-light rounded-5"><p style="font-size:10px; text-align: left;">PDF citation</p>${pdfCitations.join("")}</span></div>` : "";
				// const websiteCitationText = websiteCitations.length > 0 ? `<div class="msg-box" style="display:none"><span class=""><p style="font-size:10px; text-align: left;">Walden Owned Public Data Source</p>${websiteCitations.join("")}</span></div>` : "";
				// const unverifiedText = unverified.length > 0 ? `<div class="msg-box"><span class=""><p style="font-size:10px; text-align: left;">Walden UnVerified Public Data Source</p>${unverified.join("")}</span></div>` : "";

				setTimeout(function () {

					
					var typingBubble = document.querySelector('.media.received.typing-wrap');
					if (typingBubble) {
						typingBubble.parentNode.removeChild(typingBubble);
					}

					var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
					chatBox.scrollTop = chatBox.scrollHeight;

					const textElement = document.getElementById('streambubble');
					var streamingBubble = document.querySelector('.media.received.streambubble');
					if (streamingBubble) {
						streamingBubble.parentNode.removeChild(streamingBubble);
					}
					var checkFeedbackOptions = ['Response is too slow', 'Response is partially or completely incorrect (Please provide more details in the comments below)', 'Data source needs to be added or updated (Please provide more details in the comments below)'];
					
					$(`	
					<li class="media received streambubble">
						<div class="avatar avatar-xs avatar-soft-purple avatar-rounded"><img src="${window.AssetsPath.brandingAssetsStudent.Charlotte}" alt="user" class="avatar-img" style="height: auto; width: auto;"></div>
							<div class="media-body"><div class="msg-box">
								<div>
									<p id="${convo_id}_query">Generating results for <strong>${rephrased_query}</strong></p>
								</div>
								
							</div>
							<div class="msg-box">
								<div id="${convo_id}_response">
									<p id="${convo_id}_answer">${processedText}</p>
									<div class="typing" style="justify-content: right;padding-top: 10px;">
										<div class="dot"></div>
										<div class="dot"></div>
										<div class="dot"></div>
									</div>
								</div>
								
							</div>
							<div style="border: 1px solid #D0DFF6;" class="mt-2">
								<div id="action-buttons">
								<div class="msg-action" style="background:transparent;">
								<p id="${convo_id}_action_update" value='' hidden></p>
								<button id="like_convo" data-convo_id='${convo_id}' data-convo_action='like' class="btn btn-icon btn-rounded btn-flush-primary btn-lg"  data-bs-toggle="dropdown"><span class="icon"><i class="fa fa-thumbs-up"></i></span></button>
								<div id="likeCategory_${convo_id}" class="dropdown-menu dropdown-menu-end dislikeCategory">
									<a class="dropdown-item">
										<label for="convo_feedback">Would you like to share a feedback?</label>
										<div class="form-check">
										<input class="form-check-input" data-convo_id='${convo_id}' id="likecheckbox1_${convo_id}" type="checkbox" value="Cache Response">
										<label class="form-check-label" id="label1" for="checkbox1">
										Cache Response
										</label>
										  </div>
										<textarea class="form-control" data-convo_id='${convo_id}' id="${convo_id}_convo_feedback_like" type="text"></textarea>
										<button id="send_like_feedback" data-convo_id='${convo_id}' class="btn  btn-custom  btn-success icon-wthot-bg btn-rounded mt-1" style="float: inline-end;"><span><span>Send</span><span class="icon"><i class="fa fa-angle-right"></i> </span></span></button>
									</a>
								</div>
								<button id="dislike_convo" data-convo_id='${convo_id}' data-convo_action='dislike' class="btn btn-icon btn-rounded btn-flush-primary"><span class="icon"><i class="fa fa-thumbs-down"></i></span></button>
								<div id="dislikeCategory_${convo_id}" class="dropdown-menu dropdown-menu-end dislikeCategory">
									<a class="dropdown-item">
										<label for="convo_feedback">Would you like to share a feedback?</label>
										<div class="form-check">
										<input class="form-check-input" data-convo_id='${convo_id}' id="checkbox1_${convo_id}" type="checkbox" value="${checkFeedbackOptions[0]}">
										<label class="form-check-label" id="label1" for="checkbox1">
										${checkFeedbackOptions[0]}
										</label>
										  </div>
										  <div class="form-check">
										<input class="form-check-input" data-convo_id='${convo_id}' id="checkbox2_${convo_id}" type="checkbox" value="${checkFeedbackOptions[1]}">
										<label class="form-check-label" id="label2" for="checkbox2">
										Response is partially or completely incorrect <br>
               		 					<span>(Please provide more details in the comments below)</span>
										</label>
										  </div>
										  <div class="form-check">
										<input class="form-check-input" data-convo_id='${convo_id}' id="checkbox3_${convo_id}" type="checkbox" value="${checkFeedbackOptions[2]}">
										<label class="form-check-label" id="label3" for="checkbox3">
										Data source needs to be added or updated <br>
                						<span>(Please provide more details in the comments below)</span>
										</label>
										  </div>
										  
										  <textarea class="form-control" data-convo_id='${convo_id}' id="${convo_id}_convo_feedback_dislike" type="text" placeholder="Provide additional feedback"></textarea>
										<button id="send_dislike_feedback" data-convo_id='${convo_id}' class="btn  btn-custom  btn-success icon-wthot-bg btn-rounded mt-1" style="float: inline-end;" disabled><span><span>Send</span><span class="icon"><i class="fa fa-angle-right"></i> </span></span></button>
									</a>
									
								</div>
								<a id="enrollment_citation_btn_${convo_id}" data-convo_id='${convo_id}' style="margin: 2px 10px 2px 2px; margin-button: 15px; border: none; cursor: pointer"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16" style="margin-right: 5px;">
								<path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
								<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
							  </svg>Citation</a>
							  <a id="copy_response_btn_env" data-convo_id='${convo_id}' style="margin: 2px 10px 2px 2px; margin-button: 15px; border: none; cursor: pointer"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16" style="margin-right: 5px;">
							  <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
							  </svg>Copy Response</a>
							  <a id="disclaimer" data-convo_id='${convo_id}' data-convo_action='disclaimer'  data-bs-toggle="dropdown"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
							<path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"/>
							
						  </svg>Disclaimer</a>
						  
						  <div class="dropdown-menu dropdown-menu-end">
									<a class="dropdown-item">
									<p class="form-control" data-convo_id='${convo_id}' id="${convo_id}_disclaimer" type="text" style="border-color: red; white-space: normal;">
						  <strong>Disclaimer:</strong> While interacting in this chat window, you are interacting
						  with a chatbot that uses generative AI, or materials created by generative
						  AI. If you are ever transferred to a real person, our human representative
						  will introduce themselves during the chat. If you have any questions
						  regarding this notice, please email Algovernance@adtalem.com
					  </p>
									</a>
								</div>
							</div>
							</div>
								</div>
								
							</div>
							<div id="${convo_id}_cached_response_text" data-convo_id='${convo_id}' style="display: none; size: 16px" class="mt-2">
                            <b>${window.env.enrollmentConfig.cachedResponseConstant}</b>
                            </div>
							<div id="${convo_id}_apology_text" data-convo_id='${convo_id}' style="display: none; font-family: Halant; size: 16px" class="mt-2">
							<p>Sorry for the inadequate response. As a bot, I will use this feedback to get better!</p>
							</div>
							<div id="citation_msg_div_${convo_id}" class="mt-2" style="display:none">
							</div>
						</div>
					</li>`).appendTo(".hk-chatbot-popup .chatbot-popup-body ul.list-unstyled");

					var chatBox = document.querySelector('.chatbot-popup-body .simplebar-content-wrapper')
					chatBox.scrollTop = chatBox.scrollHeight;
					if (inputText.includes('<!-- Cached Response -->') || inputText.includes('Cached Response')) {
						findCatchResponse(convo_id);
					}
				}, 0);
			}
		}
		getCitationLinksApiCallForEnrollment(citationPayloadLinks, convo_id);
	}
}

	return;
}

async function getCitationLinksApiCallForEnrollment(citationPayloadLinks, convo_id) {
	let headers = {
		"Content-Type": "application/json",
	}
	headers = await addSubscriptionKeyForEnr(headers);
	if (citationPayloadLinks.length !== 0) {
		const response = await fetch(window.env.commonConfig.citationPayloadLink, {
			method: "POST",
			headers: headers,
			body: JSON.stringify({
				chatbot_id: window.env.enrollmentConfig.chatBotIdEnr,
				convo_id: convo_id,
				sourcefiles: citationPayloadLinks
			}),
		});
		let responseData = await response.json();
		let citationCategorygroup = responseData['citationcategory_group'];
		for (let dataSourceKey in citationCategorygroup) {
		let linkArray = generateCitationLinks(citationCategorygroup[dataSourceKey]);
		if (linkArray.length > 0) {
			let msgDiv = dynamicMsgboxGenerator(dataSourceKey, linkArray);
			var getCitationMsgDiv = document.getElementById("citation_msg_div_" + convo_id);
			getCitationMsgDiv.innerHTML += msgDiv;
		}
		}
	} 
}

function generateCitationLinks(citationArray) {
	let modifiedArray = [];
	citationArray.forEach(citationObj => {
		citationObj.Title = citationObj.Title.replace(/<\/?[^>]+(>|$)/g, "");
		const citationNumber = citationsForTitle[citationObj.URL].citationNumber;
		const shortenedTitle = citationObj.Title.length > 30 ? citationObj.Title.substring(0, 30) + '...' : citationObj.Title;
			modifiedArray.push(`<a href="${citationObj.URL}" class="badge badge-primary mt-2" style="margin-left:2px;" target="_blank" title="${citationObj.Title}">${citationNumber}. ${shortenedTitle}...</a>`);
	});
	modifiedArray.sort((a, b) => {
		const numA = parseInt(a.match(/>(\d+)\./)[1], 10);
		const numB = parseInt(b.match(/>(\d+)\./)[1], 10);
		return numA - numB;
	});
	return modifiedArray;
}

function dynamicMsgboxGenerator(dataSourceKey, linksArray) {
	 return `<div class="msg-box"><span class="p-1 border border-light rounded-5"><p style="font-size:10px; text-align: left;">${dataSourceKey}</p>${linksArray.join("")}</span></div>`;
}

async function likeConvoEnrollmentChatbot(e, id){
	var convoID = id.getAttribute('data-convo_id');
	var getDislikeButton = id.parentElement.children[3];
	if(getDislikeButton.style.color === 'rgb(5, 168, 135)') {
		getDislikeButton.style.color = null;
		var getApologyDiv = document.getElementById(convoID+"_apology_text");
		getApologyDiv.style.display = 'none';
	}
	id.style.color='#05a887';
	accessLikeTextareaByConvoId(convoID);
	document.getElementById(convoID+"_action_update").value = "like";
	var query = document.getElementById(convoID+"_query").textContent;
	var answer = document.getElementById(convoID+"_answer").textContent;
	let headers = {
		"Content-Type": "application/x-www-form-urlencoded",
	  };
	  headers = await addSubscriptionKeyForEnr(headers);
	$.ajax({
		type: "POST",
		headers: headers,
		url: window.env.commonConfig.convoAction,
		data: {
			chatbot_id: window.env.enrollmentConfig.chatBotIdEnr,
			session_id: localStorage.getItem('session_uuid'),
			convo_id: id.getAttribute('data-convo_id'),
			user:query,
			feedback: "",
			bot:answer,
			thumbs_up_down: id.getAttribute('data-convo_action')
		},
	});
}

async function dislikeConvoEnrollmentChatbot(e, id){
	var getLikeButton = id.parentElement.children[1];
	if(getLikeButton.style.color === 'rgb(5, 168, 135)') {
		getLikeButton.style.color = null;
	}
	id.style.color='#05a887';
	var convoID = id.getAttribute('data-convo_id');
	accessDislikeTextareaByConvoId(convoID);
	document.getElementById(convoID+"_action_update").value = "dislike";
	var query = document.getElementById(convoID+"_query").textContent;
	var answer = document.getElementById(convoID+"_answer").textContent;
	let headers = {
		'Content-Type': 'application/x-www-form-urlencoded',
	}
	headers = await addSubscriptionKeyForEnr(headers);
	$.ajax({
		type: "POST",
		headers: headers,
		url: window.env.commonConfig.convoAction,
		data: {
			chatbot_id: window.env.enrollmentConfig.chatBotIdEnr,
			session_id: localStorage.getItem('session_uuid'),
			convo_id: id.getAttribute("data-convo_id"),
			user:query,
			feedback: "",
			bot:answer,
			thumbs_up_down: id.getAttribute("data-convo_action")
		}
	});
}

async function sendDislikeFeedbackEnrollmentChatbot(e, id){
	var checkedLabels = [];
	var convoId = id.getAttribute("data-convo_id");
	for(let i=1;i<=3;i++){
		var checkbox = document.getElementById(`checkbox${i}_${convoId}`);
		if(checkbox.checked){
			checkedLabels.push(checkbox.value);
		}
	}
	var value = document.getElementById(convoId+"_convo_feedback_dislike").value;
	var query = document.getElementById(convoId+"_query").textContent;
	var answer = document.getElementById(convoId+"_answer").textContent;
	var checkedLabels = checkedLabels.toString();
	var action = document.getElementById(convoId+"_action_update").value;
	let headers = {
		'Content-Type': 'application/x-www-form-urlencoded',
	}
	headers = await addSubscriptionKeyForEnr(headers);
	setTimeout(function(){
		$.ajax({
			type: "POST",
			url: window.env.commonConfig.convoFeedback,
			headers: headers,
			data: {
				chatbot_id: window.env.enrollmentConfig.chatBotIdEnr,
				session_id: localStorage.getItem('session_uuid'),
				convo_id: convoId,
				feedback: value,
				feedback_category:checkedLabels,
				user:query,
				bot:answer,
				thumbs_up_down:action
			}
		});
	},0);

	var accessApologyDiv = document.getElementById(convoId+"_apology_text");
	accessApologyDiv.style.display = 'block';
	$(`#dislikeCategory_${convoId}`).removeClass('show');
	isDislikePopupOpen = false;
	inputTextStatus(true);
	return;
}

async function sendLikeFeedbackEnrollmentChatbot(e, id){
	var checkedLabels = '';
	var convoId = id.getAttribute("data-convo_id");
	var checkbox = document.getElementById(`likecheckbox1_${convoId}`);
	if (checkbox.checked) {
		checkedLabels = checkbox.value;
	}
	var value = document.getElementById(convoId+"_convo_feedback_like").value;
	var query = document.getElementById(convoId+"_query").textContent;
	var answer = document.getElementById(convoId+"_answer").textContent;
	var action = document.getElementById(convoId+"_action_update").value;
	let headers = {
		'Content-Type': 'application/x-www-form-urlencoded',
	}
	headers = await addSubscriptionKeyForEnr(headers);
	setTimeout(function(){
		$.ajax({
			type: "POST",
			url: window.env.commonConfig.convoFeedback,
			headers: headers,
			data: {
				chatbot_id: window.env.enrollmentConfig.chatBotIdEnr,
				session_id: localStorage.getItem('session_uuid'),
				convo_id: convoId,
				feedback: value,
				user:query,
				bot:answer,
				thumbs_up_down:action
			}
		});
	},0);

	inputTextStatus(true);
	return;
}

function accessDislikeTextareaByConvoId(convoId) {
	var getTextArea = document.getElementById(convoId + "_convo_feedback_dislike");
	var getSendDislikeButton = getTextArea.nextElementSibling;
	var checkboxCount = 0;
	$(".form-check-input").on("click", function(){
		if($(this).prop('checked')){
			 checkboxCount++;
		  } else {
			  checkboxCount--;
		  }
		  if($(this).prop('unchecked')){
		}
		  if (checkboxCount > 0) {
			getSendDislikeButton.disabled = false;
		  } else {
			getSendDislikeButton.disabled = true;
		  }
	  }); 
}


function accessLikeTextareaByConvoId(convoId) {
	var getTextArea = document.getElementById(convoId + "_convo_feedback_like");
	getSendLikeButton = getTextArea.nextElementSibling;
	getTextArea.addEventListener('input', function (e) {
		if (getTextArea.value.trim() !== '') {
			getSendLikeButton.disabled = false;
		} else {
			getSendLikeButton.disabled = true;
		}
	})
}

async function loadHistoryForEnrollment(){
	const currentUrl = window.location.pathname;
	if (currentUrl !== chatbotPath) {
	var jsonData = {
		chatbot_id: window.env.enrollmentConfig.chatBotIdEnr,
		userEmail:sessionStorage.getItem('userEmail'),
		group_by_date: "yes"
	};
	let headers = {
		'Content-Type': 'application/json',
	}
	headers = await addSubscriptionKeyForEnr(headers);
	$.ajax({
		type: 'POST',
		url: window.env.commonConfig.chatHistory,
		headers: headers,
		data: JSON.stringify(jsonData),
		success: function (response) {
			var reorderRes = response.chat_history;
            let reversedObject = {};
            let keys = Object.keys(reorderRes).sort((a, b) => new Date(b) - new Date(a));
            keys.forEach(key => {
                reversedObject[key] = reorderRes[key];
            });
            var lengthRes = Object.keys(reversedObject).length;
			// let user_name = sessionStorage.getItem('userName');
			// user_name = user_name.replace(",","");
			// let userName = user_name.split(" ");
			// user_name = userName[1]+" "+userName[0];
			// var chatHistoryTitle = document.getElementById("chat-history-title");
			// chatHistoryTitle.innerHTML = `Prospect Name: <span style="font-weight:400;font-size: 22px;">${user_name}</span>`;
			var chatHistoryTitle = document.getElementById("chat-history-title");
			chatHistoryTitle.style.display = 'none';
			if(lengthRes >0){
				$('.noHistory').remove()
				loadChatHistoryForEnrollment(reversedObject);
			}else{
				if(!noHistory){
					var noHistoryContainer = document.getElementById("chat_history");
					var noHistoryTag = document.createElement("div");
					noHistoryTag.classList.add("noHistory");
					noHistoryTag.innerText = "No History...";
					noHistoryContainer.appendChild(noHistoryTag);
					noHistory = true;
				}
				console.log("No history in database.");
			}
		},
		error: function (data) {
			var chatHistoryError = document.getElementById('chat-history-title');
			chatHistoryError.innerText = "Failed to load history. Please try again..."
			
		}
	});
	} else {
		
		var getChatbotElement = document.getElementById('chatbot-body');
		
		if (getChatbotElement.classList.contains('welcome-acknowledge-body')) {
			console.log('welcome-acknowledge-body');
		} else {
			console.log('The class "chatbot-popup-body" is applied.');
			//const authToken = await getAccessTokenFromOktaApi();
	var jsonData = {
		chatbot_id: window.env.enrollmentConfig.chatBotIdEnr,
		userEmail: sessionStorage.getItem('userEmail'),
		group_by_date: "yes"
	};
	let headers = {
		'Content-Type': 'application/json',
	}
	headers = await addSubscriptionKeyForEnr(headers);
	$.ajax({
		type: 'POST',
		url: window.env.commonConfig.chatHistory,
		headers: headers,
		data: JSON.stringify(jsonData),
		success: function (response) {
			var reorderRes = response.chat_history;
            let reversedObject = {};
            let keys = Object.keys(reorderRes).sort((a, b) => new Date(b) - new Date(a));
            keys.forEach(key => {
                reversedObject[key] = reorderRes[key];
            });
            var lengthRes = Object.keys(reversedObject).length;
			// let user_name = sessionStorage.getItem('userName');
			// user_name = user_name.replace(",","");
			// let userName = user_name.split(" ");
			// user_name = userName[1]+" "+userName[0];
			// var chatHistoryTitle = document.getElementById("chat-history-title");
			// chatHistoryTitle.innerHTML = `Prospect Name: <span style="font-weight:400;font-size: 22px;">${user_name}</span>`;
			var chatHistoryTitle = document.getElementById("chat-history-title");
			chatHistoryTitle.style.display = 'none';
			if(lengthRes >0){
				$('.noHistory').remove()
				loadChatHistoryForEnrollment(reversedObject);
			}else{
				if(!noHistory){
					var noHistoryContainer = document.getElementById("chat_history");
					var noHistoryTag = document.createElement("div");
					noHistoryTag.classList.add("noHistory");
					noHistoryTag.innerText = "No History...";
					noHistoryContainer.appendChild(noHistoryTag);
					noHistory = true;
				}
				console.log("No history in database.");
			}
		},
		error: function (data) {
			var chatHistoryError = document.getElementById('chat-history-title');
			chatHistoryError.innerText = "Failed to load history. Please try again..."
			
		}
	});

	$('.chatbot-popup-body').addClass('history-popup-body');
            $('.nicescroll-bar').addClass('d-none');
            $('.chat-history').removeClass('d-none');
            $('.list-unstyled,footer > .input-group').removeClass('d-none');
            activeHistoryTab();	
			inputTextStatus(true);
	}
}
	const messageInputField = document.getElementById("input_msg_chat_popup");
	messageInputField.value = '';
	updateSendButton();
}

function loadChatHistoryForEnrollment(data){
	let i = 0;
	for(const date in data){
		var historyForDate =data[date];
		var queryDate = date;
		var historyTagId = "historyDateDivison"+i;
		var queriesTagId = "queriesTagId"+i;
		i++;
		var chatHistoryContainer = document.getElementById("chat_history");
		var removeHistoryContainer = document.getElementById(historyTagId);
		if(removeHistoryContainer){
			chatHistoryContainer.removeChild(removeHistoryContainer);
		}
		var historyDateDiv = document.createElement("div");
		historyDateDiv.setAttribute("id",historyTagId)
		chatHistoryContainer.appendChild(historyDateDiv);
		var dateTag = document.createElement("h6");
		dateTag.style.fontFamily = 'Mulish';
		dateTag.style.fontSize = '16px';
		dateTag.style.color = "#000000";
		dateTag.style.fontWeight = "800"
		var queriesTag = document.createElement("div");
		queriesTag.setAttribute("id",queriesTagId);
		queriesTag.style.display = "grid";
		queriesTag.style.fontFamily = 'Mulish';
		queriesTag.style.fontSize = '16px';
		queriesTag.style.marginTop = "0.3rem";
		queriesTag.style.marginBottom = "2rem";
		queriesTag.style.color="#0058ad";
		const formattedDate = formatDate(queryDate);
		dateTag.textContent = formattedDate;
		var dateHistoryContainer = document.getElementById(historyTagId);
		dateHistoryContainer.appendChild(dateTag);
		dateHistoryContainer.appendChild(queriesTag);
		historyForDate.forEach(function(history){
			historySetupForEnrollment(queriesTagId, history.user);
		})
	}
}

function historySetupForEnrollment(id,query){
	var queriesContainer = document.getElementById(id);
	var queryTag = document.createElement("button");
	queryTag.textContent = query;
	queryTag.classList.add("btn");
	queryTag.classList.add("history-query");
	queryTag.style.border= "none";
	queryTag.style.padding= '2px 0';
	queryTag.style.textAlign= "start";
	queryTag.style.textDecoration= "underline";
	queryTag.style.color= "#0058ad";
	queryTag.style.fontFamily="Mulish";
	queriesContainer.appendChild(queryTag);
}

function formatDate(dateString) {
	
	dateArray = dateString.split("-");
	
	// Array of month names
	const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	// Get the day and month
	const day = dateArray[2];
	const month = dateArray[1]-1;
	const year = dateArray[0]
	// Function to get the suffix for the day
	function getDaySuffix(day) {
	  if (day > 3 && day < 21) return "th";
	  switch (day % 10) {
		case 1:  return "st";
		case 2:  return "nd";
		case 3:  return "rd";
		default: return "th";
	  }
	}
	// Format the day with suffix
	const formattedDay = day + getDaySuffix(day);
	// Get the month name
	const formattedMonth = monthNames[month];
	// Combine and return the formatted date
	return formattedDay + " " + formattedMonth + " " + year;
}

$(document).on('click', "[id^='enrollment_citation_btn_']", function (e) {
	var convoId = $(this).data('convo_id');
	var getCitationMsgDiv = document.getElementById('citation_msg_div_' + convoId);
	getCitationMsgDiv.style.display = (getCitationMsgDiv.style.display == 'block' ) ? 'none' :  'block';
});

$(document).on('click', "#copy_response_btn_env", function (e) {
	const currentUrl = window.location.pathname;
	let responseContent = "";
	if (currentUrl !== salesforceChatbotPath) {
		var convoId = $(this).data('convo_id');
		var responseCopy = document.getElementById(convoId+"_response");
		 responseContent = responseCopy.innerText;
		navigator.clipboard.writeText(responseContent).then(function(){
			
		}).catch(function(err){
			console.log("Failed to copy content : ",err);
		})
	} else {
		var convoId = $(this).data('convo_id');
		var responseCopy = document.getElementById(convoId+"_response");
		responseContent = responseCopy.innerText;
		copyResponseFromSalesforce(responseContent);
	}
});

function activeHistoryTab(){
	$('#history_tab .bottom_border').css('display','block');
	$('#faq_tab .bottom_border').css('display','none');
	$('#faqTab').css('display','none');
	$('#historyTab').css('display','block');
	$('#faq_tab').css('color','#C4C4C4');
	$('#history_tab').css('color','#00334C');
	inputTextStatus(true);
}

$(document).on('click', "#faq_tab", function(e){
	$('#faq_tab .bottom_border').css('display','block');
	$('#history_tab .bottom_border').css('display','none');
	$('#historyTab').css('display','none');
	$('#faqTab').css('display','block');
	$('#history_tab').css('color','#C4C4C4');
	$('#faq_tab').css('color','#00334C');
})

$(document).on('click', "#history_tab", function(e){
	activeHistoryTab();
});

$(document).on('click', '#dislike_convo', function(e){
	if(isDislikePopupOpen === false){
		$(`#dislikeCategory_${convoId}`).addClass('show');
		isDislikePopupOpen = true;
	}else {
		$(`#dislikeCategory_${convoId}`).removeClass('show');
		isDislikePopupOpen = false;
	}
	e.stopPropagation();
})

//Method to disable/enable input test area to ask query
// function inputTextStatus(status){
// 	var inputTag = document.getElementById("input_msg_chat_popup");
// 	if(!status){
// 		inputTag.disabled = true;
// 		inputTag.placeholder = "please provide your feedback to continue.";
// 		var getSendBtn = document.getElementById('send_btn_icon');
// 		getSendBtn.setAttribute('fill', '#EBEBEB');
// 		// localStorage.setItem('feedbackprovide', 'false');
// 		var getButton = document.getElementById('refresh_chat');
// 		getButton.disabled = true;
// 		// getButton.style.pointerEvents = 'none';
// 		getButton.style.cursor = 'not-allowed';
// 		var getHistoryButton = document.getElementById('history_chat');
// 		getHistoryButton.disabled = true;
// 		getHistoryButton.style.cursor = 'not-allowed';
// 	} else {
// 		inputTag.disabled = false;
// 		inputTag.placeholder = "Ask me anything...";
// 		// localStorage.setItem('feedbackprovide', 'true');
// 		var getButton = document.getElementById('refresh_chat');
// 		getButton.style.cursor = 'pointer';
// 		getButton.disabled = false;
// 		var getHistoryButton = document.getElementById('history_chat');
// 		getHistoryButton.disabled = false;
// 		getHistoryButton.style.cursor = 'pointer';
// 		// var getSendBtn = document.getElementById('send_btn_icon');
// 		// getSendBtn.setAttribute('fill', '#003a53');
// 	}
// }

$(document).on('click', ".dislikeCategory", function(e){
	e.stopPropagation();
})

$(document).on('click', function(e){
	$(`#dislikeCategory_${convoId}`).removeClass('show');
	isDislikePopupOpen = false;
})

function extractNumberAndPrependTitle(url, websiteCitations) {
	var number;
	websiteCitations.forEach(citation => {
		var tempDiv = document.createElement('div');
        tempDiv.innerHTML = citation;
		var href = tempDiv.firstChild.getAttribute('href');
		if (href == url) {
			number = tempDiv.firstChild.textContent.trim().split('.')[0];
		}
	})
	return number;
}

function changeSendButtonColor() {
	var inputTag = document.getElementById("input_msg_chat_popup");
		inputTag.addEventListener('keydown', updateSendButton);
		inputTag.addEventListener('keyup', updateSendButton);
}

function updateSendButton() {
	var inputTag = document.getElementById("input_msg_chat_popup");
	if (inputTag.value.trim() !== '') {
		var getSendBtn = document.getElementById('send_btn_icon');
		getSendBtn.setAttribute('fill', '#003a53');
	} else {
		var getSendBtn = document.getElementById('send_btn_icon');
		getSendBtn.setAttribute('fill', '#EBEBEB');
	}
}

function copyResponseFromSalesforce(responseContent) {
  const message = { function: 'CHATBOT_COPY_RESPONSE', response: responseContent };
  console.log('enrollmentConfig.salesforceConfig.url: ', window.env.enrollmentConfig.salesforceConfigUrl);
  window.parent.postMessage(message, window.env.enrollmentConfig.salesforceConfigUrl);
}

function findCatchResponse(convoId) {
	var getCitationButton = document.getElementById('enrollment_citation_btn_' + convoId);
	getCitationButton.style.disabled = true;
	getCitationButton.style.cursor = 'not-allowed';
	var getCachedResponseText = document.getElementById(convoId + "_cached_response_text");
	getCachedResponseText.style.display = 'block';
	
}

function removeCitationLinksFromResponse(inputText) {
	const urlPattern = /\[https?:\/\/[^\s\[\]]+\]/g;
	return inputText.replace(urlPattern, '');
}

async function addSubscriptionKeyForEnr(headers) {
	const subscriptionKey = window.env.enrollmentConfig.apimSubscriptionKeyForEnr;
    const token = await getValidAccessToken();
    if (window.env.apimConfigurationEnable) {
        headers['Ocp-Apim-Subscription-Key'] = subscriptionKey;
        headers['Authorization'] = 'Bearer ' + token;
        headers['AuthenticationType'] = 'Application';
    }
    // Return the updated headers
    return headers;
}

// window.onbeforeunload = (event) => {const e = event || window.event;
// 	console.log('onbefore load event: ', e);
// 	var status = localStorage.getItem('feedbackprovide');
// 	if (status && e) {
// 		e.returnValue = 'Please dont reload without feedback'; // Legacy method for cross browser support
// 		}return 'dont reload'; 
// 		// Legacy method for cross browser support
// 		};

